import React from "react";
import Layout from "./components/Layout";
import GlobalStyle from "./globalStyles";

const App = ({ children }) => {
  const { pathname } = window.location;

  return (
    <>
      <GlobalStyle />
      {pathname === "/connect" || pathname === "/tv" ? (
        <>{children}</> // Render only the content for /connect and /tv
      ) : (
        <Layout>{children}</Layout> // Render everything else with Layout
      )}
    </>
  );
};

export default App;
