import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { formComponents } from "../components/forms"; // Import the formComponents mapping

// Mapping for titles and descriptions based on form name
const formDetails = {
  media: {
    title: "Media Contact",
    description:
      "Please fill out the Media Contact Form to get in touch with us regarding media inquiries.",
  },
  teamBuild: {
    title: "Team Build Opportunity",
    description:
      "Fill out the Team Build Opportunity Form to organize a build day with your group.",
  },
  youthProgram: {
    title: "Youth Programs Inquiry",
    description:
      "Complete the Youth Programs Inquiry Form to learn more about our programs for young people.",
  },
  donation: {
    title: "Organization Donations",
    description:
      "Use this form to inquire about donating items to Habitat for Humanity Buffalo and a representative will contact you to confirm details.",
  },
  contractor: {
    title: "Contractor Inquiry",
    description:
      "Fill out the Contractor Inquiry Form to express interest in working with us.",
  },
  sponsorship: {
    title: "Sponsorship Packages Request",
    description:
      "Submit this form to request sponsorship opportunities with Habitat for Humanity Buffalo.",
  },
  workplaceGiving: {
    title: "Workplace Giving",
    description:
      "Complete the Workplace Giving Form to get involved with your company's charitable efforts.",
  },
  giving: {
    title: "Giving Inquiry",
    description:
      "Use this form to learn more about various giving opportunities with Habitat for Humanity Buffalo.",
  },
  plannedGiving: {
    title: "Planned Giving",
    description:
      "Discover how you can make a lasting impact through planned giving.",
  },
  athletic: {
    title: "Athletic Fundraiser",
    description:
      "Register your athletic event to support Habitat for Humanity Buffalo.",
  },
  repair: {
    title: "Home Repair Application",
    description:
      "Please fill out the Home Repair Form and a representative will contact you regarding your eligibility and next steps.",
  },
  winterWarrior: {
    title: "Winter Warriors",
    description:
      "Please fill out the Winter Warriors Form and a representative will contact you with next steps.",
  },
  faith: {
    title: "Faith and Community",
    description:
      "Please fill out the form and a representative will contact you with next steps.",
  },
  fundraiser: {
    title: "Host A Habitat Fundraiser",
    description:
      "Please fill out the form and a representative will contact you with next steps.",
  },
  campusChapter: {
    title: "Start A Campus Chapter",
    description:
      "Please fill out the form to get in touch with us regarding forming a campus chapter at your school.",
  },
};

const DynamicFormPage = () => {
  const { formName } = useParams();
  const FormComponent = formComponents[formName];

  // Get specific title and description based on form name
  const { title, description } = formDetails[formName] || {
    title: "Form Not Found",
    description: "Sorry, the form you're looking for doesn't exist.",
  };

  if (!FormComponent) {
    return (
      <Container>
        <Header>{title}</Header>
        <Paragraph>{description}</Paragraph>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title} - Habitat for Humanity Buffalo</title>
        <meta name="description" content={description} />
      </Helmet>
      <Container>
        <Header>{title}</Header>
        <Paragraph>{description}</Paragraph>
        <FormContainer>
          <FormComponent />
        </FormContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.h1`
  text-align: center;
  font-size: var(--font-size-title);
  color: var(--primary-color);
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  font-size: var(--font-size-body);
  line-height: var(--line-height);
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  background-color: var(--hhb-white);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export default DynamicFormPage;
