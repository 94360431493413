import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaFacebook,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import logo from "../assets/images/logos/Buffalo_Hz_White.webp";
import DropdownMenu from "./DropdownMenu";

const XLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
  </svg>
);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmenuClick = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const trackEvent = (action, label) => {
    window.gtag("event", action, {
      event_category: "Navbar",
      event_label: label,
    });
  };

  const whoWeAreItems = [
    {
      label: "About Habitat Buffalo",
      link: "/who-we-are/about-habitat-buffalo",
    },
    { label: "Our Homes", link: "/who-we-are/our-homes" },
    { label: "Newsroom", link: "/who-we-are/newsroom" },
    { label: "Meet Habitat Buffalo", link: "/who-we-are/meet-habitat-buffalo" },
    { label: "Career Opportunities", link: "/who-we-are/career-opportunities" },
  ];

  const howToApplyItems = [
    { label: "Homeownership", link: "/how-to-apply/homeownership" },
    { label: "Home Repairs", link: "/how-to-apply/home-repairs" },
  ];

  const getInvolvedItems = [
    {
      label: "Individual Volunteer Opportunities",
      link: "/get-involved/individual-volunteer-opportunities",
    },
    {
      label: "Team Build Opportunities",
      link: "/get-involved/team-build-opportunities",
    },
    {
      label: "Sponsorship Packages",
      link: "/get-involved/sponsorship-packages",
    },
    { label: "Winter Warriors", link: "/get-involved/winter-warriors" },
    {
      label: "Veterans/Military Families",
      link: "/get-involved/veterans-military-families",
    },
    {
      label: "Habitat Young Professionals",
      link: "/get-involved/habitat-young-professionals",
    },
    {
      label: "Campus Chapters",
      link: "/get-involved/campus-chapters",
    },
    { label: "Faith and Community", link: "/get-involved/faith-and-community" },
    { label: "Youth Programs", link: "/get-involved/youth-programs" },
    {
      label: "Become a Habitat Buffalo Contractor",
      link: "/get-involved/become-a-habitat-buffalo-contractor",
    },
  ];

  const supportUsItems = [
    { label: "Donate Items", link: "/support-us/donate-items" },
    { label: "Donate Funds", link: "/support-us/donate-funds" },
    { label: "Donate a Car", link: "/support-us/donate-a-car" },
    {
      label: "Host Your Own Fundraiser",
      link: "/support-us/host-your-own-fundraiser",
    },
    { label: "Other Ways to Give", link: "/support-us" },
  ];

  const restoreItems = [
    { label: "Locations and Hours", link: "/restore/locations-and-hours" },
    { label: "Donate Items", link: "/restore/donate-items" },
    { label: "What We Sell", link: "/restore/what-we-sell" },
    { label: "ReStore FAQ", link: "/restore/restore-faq" },
  ];

  return (
    <Header>
      <TopBar>
        <SocialIcons>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.x.com" // Update this link with your X profile
            target="_blank"
            rel="noopener noreferrer"
          >
            <XLogo />
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </SocialIcons>
      </TopBar>
      <LogoContainer>
        <Link to="/">
          <img src={logo} alt="Habitat for Humanity Buffalo" />
        </Link>
        <HamburgerMenu onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </HamburgerMenu>
      </LogoContainer>
      <DesktopNavbar>
        <NavMenu>
          <DropdownMenu
            title="WHO WE ARE"
            items={whoWeAreItems}
            mainLink="/who-we-are"
          />
          <DropdownMenu
            title="HOW TO APPLY"
            items={howToApplyItems}
            mainLink="/how-to-apply"
          />
          <DropdownMenu
            title="GET INVOLVED"
            items={getInvolvedItems}
            mainLink="/get-involved"
          />
          <DropdownMenu
            title="SUPPORT US"
            items={supportUsItems}
            mainLink="/support-us"
          />
          <DropdownMenu
            title="RESTORE"
            items={restoreItems}
            mainLink="/restore"
          />
          <NavLink
            onClick={() => trackEvent("click", "Events")}
            to="/get-involved/events"
          >
            EVENTS
          </NavLink>
          <NavLink onClick={() => trackEvent("click", "Contact")} to="/contact">
            CONTACT
          </NavLink>
          <NavLinkCTA
            onClick={() => trackEvent("click", "Donate")}
            to="/donate"
          >
            DONATE
          </NavLinkCTA>
        </NavMenu>
      </DesktopNavbar>
      <MobileNavbar isOpen={isOpen}>
        <MobileNavMenu>
          <MobileNavItem onClick={() => handleSubmenuClick(0)}>
            WHO WE ARE
          </MobileNavItem>
          {openSubmenu === 0 && (
            <MobileSubMenu>
              {whoWeAreItems.map((item) => (
                <MobileSubNavLink
                  key={item.link}
                  to={item.link}
                  onClick={() => trackEvent("click", item.label)}
                >
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(1)}>
            HOW TO APPLY
          </MobileNavItem>
          {openSubmenu === 1 && (
            <MobileSubMenu>
              {howToApplyItems.map((item) => (
                <MobileSubNavLink
                  key={item.link}
                  to={item.link}
                  onClick={() => trackEvent("click", item.label)}
                >
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(2)}>
            GET INVOLVED
          </MobileNavItem>
          {openSubmenu === 2 && (
            <MobileSubMenu>
              {getInvolvedItems.map((item) => (
                <MobileSubNavLink
                  key={item.link}
                  to={item.link}
                  onClick={() => trackEvent("click", item.label)}
                >
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(3)}>
            SUPPORT US
          </MobileNavItem>
          {openSubmenu === 3 && (
            <MobileSubMenu>
              {supportUsItems.map((item) => (
                <MobileSubNavLink
                  key={item.link}
                  to={item.link}
                  onClick={() => trackEvent("click", item.label)}
                >
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavItem onClick={() => handleSubmenuClick(4)}>
            RESTORE
          </MobileNavItem>
          {openSubmenu === 4 && (
            <MobileSubMenu>
              {restoreItems.map((item) => (
                <MobileSubNavLink
                  key={item.link}
                  to={item.link}
                  onClick={() => trackEvent("click", item.label)}
                >
                  {item.label}
                </MobileSubNavLink>
              ))}
            </MobileSubMenu>
          )}
          <MobileNavLink
            onClick={() => trackEvent("click", "Events")}
            to="/get-involved/events"
          >
            EVENTS
          </MobileNavLink>
          <MobileNavLink
            onClick={() => trackEvent("click", "Contact")}
            to="/contact"
          >
            CONTACT
          </MobileNavLink>
          <MobileNavLinkCTA
            onClick={() => trackEvent("click", "Donate")}
            to="/donate"
          >
            DONATE
          </MobileNavLinkCTA>
        </MobileNavMenu>
      </MobileNavbar>
    </Header>
  );
};

const Header = styled.header`
  background-color: #00aeef;
  color: #fff;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    margin-left: 15px;
    font-size: 18px;
    text-decoration: none;
  }

  a:hover {
    color: #ffcc00;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  background-color: #00aeef;

  img {
    height: 80px;
  }

  @media (max-width: 1200px) {
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 1200px) {
    display: block;
    padding-right: 20px;
  }
`;

const DesktopNavbar = styled.div`
  padding-top: 5px;
  background-color: #fff;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1200px;
`;

const NavLink = styled(Link)`
  color: #000;
  text-decoration: none;
  font-weight: 700;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
  }

  @media (max-width: 1200px) {
    color: #000;
    padding: 10px 0;
  }
`;

const NavLinkCTA = styled(Link)`
  color: #fff;
  background-color: #43b02a;
  text-decoration: none;
  font-weight: 700;
  padding: 5px 22px 5px 22px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #388e20;
  }

  @media (max-width: 1200px) {
    padding: 10px 15px;
  }
`;

const MobileNavbar = styled.nav`
  background-color: #fff;
  color: #000;
  text-decoration: none;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: none;

  @media (max-width: 1200px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const MobileNavMenu = styled.ul`
  list-style: none;
  padding: 0;
`;

const MobileNavItem = styled.li`
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  cursor: pointer;
`;

const MobileNavLink = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  text-decoration: none;
  color: #000;
`;

const MobileNavLinkCTA = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #43b02a;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
`;

const MobileSubMenu = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const MobileSubNavLink = styled(Link)`
  display: block;
  padding: 15px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #eaeaea;
  text-decoration: none;
  color: #000;
`;

export default Navbar;
