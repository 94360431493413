import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sanityClient from "../sanityClient";
import { useLocation } from "react-router-dom";

const Testimonial = () => {
  const [quoteData, setQuoteData] = useState(null);
  const location = useLocation(); // Detects route changes

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "quote"]{
          quote,
          name,
          title
        }`
      )
      .then((data) => {
        if (data.length > 0) {
          const randomIndex = Math.floor(Math.random() * data.length);
          setQuoteData(data[randomIndex]);
        }
      })
      .catch(console.error);
  }, [location.pathname]); // Refetch and select a new quote when the route changes

  if (!quoteData) {
    return null; // Optionally show a loader or placeholder
  }

  const { quote, name, title } = quoteData;

  return (
    <TestimonialContainer>
      <QuoteMark>“</QuoteMark>
      <Quote>{quote}</Quote>
      <Author>
        - {name}
        {title && `, ${title}`}
      </Author>
    </TestimonialContainer>
  );
};

const TestimonialContainer = styled.div`
  text-align: center;
  margin: 50px 20px;
  padding: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const QuoteMark = styled.div`
  font-size: 6em;
  color: #00aeef;
  margin-bottom: -50px;
`;

const Quote = styled.p`
  font-size: 1.5em;
  font-style: italic;
  margin: 20px 0;
`;

const Author = styled.p`
  font-size: 1em;
  font-weight: bold;
`;

export default Testimonial;
